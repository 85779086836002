import React, { useState, useEffect } from "react";
import projectsData from "../data/projectsData";
import ProjectCard from "./ProjectCard";
import ProjectDetails from "./ProjectDetails";
import RevealLeftSlide from "../utils/RevealLeftSlide";
import RevealRightSlide from "../utils/RevealRightSlide";

const Projects = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <div className=" m-auto w-full flex justify-center items-center gap-10 px-10 mb-10 py-20 overflow-hidden">
      <div className="hidden lg:block lg:h-fit lg:w-9/12 collapse lg:visible">
        <RevealLeftSlide className="">
          <ProjectDetails data={projectsData[activeIndex]} />
        </RevealLeftSlide>
      </div>

      <div className="h-fit w-full flex flex-wrap justify-center gap-2 px-2 lg:flex-col lg:w-3/12 ">
        {projectsData.map((project, index) => (
          <RevealRightSlide key={index}>
            <div
              className={`px-4 py-4 w-fit rounded-lg cursor-pointer shadow-md transition-all border-b-4 border-x border-t border-gray-600 ${
                index === activeIndex ? "scale-95 border-red-700" : ""
              }`}
              onClick={() => handleButtonClick(index)}
            >
              <ProjectCard data={project} />
            </div>
          </RevealRightSlide>
        ))}
      </div>
    </div>
  );
};

export default Projects;
