const projectsData = [
  {
    name: "FakeSwiggy",
    shortdescription: "A food ordering application.",
    description:
      "Feeling hungry? How about you order some food to satisfy the hunger !!! - A scalable and fast performing food ordering application.",
    github: "https://github.com/Debraj550/FakeSwiggy",
    demolink: "https://fake-swiggy.vercel.app/",
    imageid: 1,
    techstack: [
      {
        name: "Frontend",
        stack: "React, Tailwind, Redux, Parcel bundler",
      },
      {
        name: "Server",
        stack: "SWIGGY API",
      },
      {
        name: "Database",
        stack: "Mongo DB",
      },
    ],
    features: [
      "The app fetches data from the live Swiggy API, which likely includes information about restaurants, menus, and possibly user accounts.",
      "Users can sort restaurants by their ratings, which is a common feature in food ordering apps to help users find highly-rated places.",
      "Users can search for specific restaurants, allowing for easy navigation and discovery.",
      "Redux is used for managing application state, which likely includes the shopping cart. Users can add items to their cart, and this state is managed via Redux.",
      "This feature likely indicates whether a restaurant is currently accepting orders (online) or not (offline).",
      "Lottie is an animation library that is often used for adding attractive animations to web applications.",
    ],
    category: "web",
  },
  {
    name: "BookFantasia",
    shortdescription: "A book selling platform for the bookworms",
    description:
      "An end to end scalable and highly responsive book selling platform for everyone. Buy / Sell book in this one stop platform.",
    github: "https://github.com/Debraj550/BookFantasia",
    demolink: "",
    imageid: 4,
    techstack: [
      {
        name: "Frontend",
        stack: "React, Bootstrap, RxJS",
      },
      {
        name: "Server",
        stack: "Django, FastAPI, Microservices",
      },
      {
        name: "Database",
        stack: "SQLite, PostgreSQL",
      },
    ],
    features: [
      "Includes book searching, sorting, secure JWT signin/signup, stripe checkout.",
      "Post book purchase a component to add review & ratings.",
      "Book selling / buying / reviewing with authorized access.",
      "Out of stock notification when book is available.",
      "Secure stripe checkout and order history.",
      "Sorting based on rating and searching based on book name / author name / ISBN",
      "Books put in the market for selling and modifying in need.",
    ],
    category: "web",
  },
  {
    name: "QnA-Guruji",
    shortdescription: "A context based QnA application.",
    description:
      "A context based QnA application made using BERT QnA model from tensorflow open source models.",
    github: "https://github.com/Debraj550/QnA-Guruji",
    demolink: "https://qna-guruji.vercel.app/",
    imageid: 2,
    techstack: [
      {
        name: "Frontend",
        stack: "React, Tailwind, Tensorflow JS, DistilBERT",
      },
    ],
    features: [
      "It's a Question Answer application trained on DistilBERT.",
      "DistilBERT is a small, fast, cheap and light Transformer model trained by distilling BERT base. It has 40% less parameters than bert-base-uncased, runs 60% faster while preserving over 95% of BERT's performances as measured on the GLUE language understanding benchmark.",
      "Given a passage / context, the model can generate a response based on the context provided.",
    ],
    category: "web",
  },

  {
    name: "debrajdhar.com (Portfolio)",
    shortdescription: "Yep, you are scrolling inside this right now.",
    description:
      "A responsive portfolio website containing my educational qualifiactions, professional work experience, skills and projects.",
    github: "",
    demolink: "http://www.debrajdhar.com",
    imageid: 3,
    techstack: [
      {
        name: "Frontend",
        stack:
          "React, Tailwind, Framer Motion, Lottie, React-Scroll, Parcel Bundler",
      },
    ],
    features: [
      "A single page portfolio application with reference to different component smooth scrolling with react-scroll.",
      "An option to switch between dark/light mode. ",
      "Framer motion sliding reveal animations.",
      "Dynamic component loading.",
      "Lazy image loading for faster performance.",
    ],
    category: "web",
  },
];

export default projectsData;
