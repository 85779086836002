import React, { useState } from "react";
import React, { useState } from "react";
import anime from "../assests/anime.png";
import original from "../assests/original.png";
import "./home.css";
import dp1 from "../assests/dp1.png";
import dp2 from "../assests/dp2.png";

const Home = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleSetActive = (to) => {
    setActiveElement(to);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="w-10/12 m-auto pt-8 pb-40 px-10 overflow-hidden ">
      <div className={`flex justify-center cursor-default`}>
        <img
          className="rounded-3xl w-96 mr-20 mb-8 transition-opacity duration-1000 ease-in-out "
          src={original}
          style={{ opacity: isHovered ? 0 : 1 }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          alt="Profile"
        />
        <img
          className="rounded-3xl w-[24rem] mr-24 ml-4  transition-opacity duration-1000 top-[33px]  ease-in-out absolute "
          src={anime}
          style={{ opacity: isHovered ? 1 : 0 }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          alt="Profile"
        />
      </div>
      <div className="text-center">
        <div className="myname mb-4">
          <h1 className="font-raleway text-xl">Hello, I'm</h1>
          <div className="font-oleo font-bold text-[8.5rem]">
            <h1 className="">
              Debraj <span className="text-blue-500 ">Dhar</span>
            </h1>
          </div>
        </div>
        <div className="description w-6/12 m-auto">
          <p className="font-raleway text-xl text-gray-500">
            The
            <span className="font-bold"> Software Developer </span>you want in
            your organization.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
