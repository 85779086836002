import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-scroll";
import ThemeContext from "../utils/ThemeContext";
import classNames from "classnames";
import { Tooltip as ReactTooltip } from "react-tooltip";

const Header = () => {
  const [activeElement, setActiveElement] = useState("");
  const { theme, toggleTheme } = useContext(ThemeContext);

  const handleSetActive = (to) => {
    setActiveElement(to);
  };

  return (
    <div
      className={classNames(
        "app fixed z-10 w-full",
        theme === "light" ? "bg-white text-black" : "bg-[#191919] text-white"
      )}
    >
      <div className="flex gap-[4.5rem] text-xl pt-4 pb-3 justify-center items-center w-full">
        <Link
          to="home"
          spy={true}
          smooth={true}
          duration={1000}
          activeClass="active"
          onSetActive={() => handleSetActive("home")}
          className={`pb-1 transition-colors duration-300 ease-in  cursor-pointer ${
            activeElement === "home"
              ? theme === "dark"
                ? "border-b-2 border-white"
                : "border-b-2 border-black"
              : "border-0"
          }
          `}
          data-tooltip-content={"Home"}
          data-tooltip-id="home"
        >
          <i className="home fa-solid fa-house hover:scale-110 duration-300"></i>
          <ReactTooltip id="home" className="scale-75" />
        </Link>

        <Link
          to="about"
          spy={true}
          smooth={true}
          duration={1000}
          activeClass="active"
          onSetActive={() => handleSetActive("about")}
          className={`pb-1 transition-colors duration-300 ease-in cursor-pointer ${
            activeElement === "about"
              ? theme === "dark"
                ? "border-b-2 border-white"
                : "border-b-2 border-black"
              : "border-0"
          }`}
          data-tooltip-content={"About"}
          data-tooltip-id="about"
        >
          <i className="fa-solid fa-user hover:scale-110 duration-300"></i>
          <ReactTooltip id="about" className="scale-75" />
        </Link>
        <Link
          to="skills"
          spy={true}
          smooth={true}
          duration={1000}
          activeClass="active"
          onSetActive={() => handleSetActive("skills")}
          className={`pb-1 transition-colors duration-300 ease-in cursor-pointer ${
            activeElement === "skills"
              ? theme === "dark"
                ? "border-b-2 border-white"
                : "border-b-2 border-black"
              : "border-0"
          }`}
          data-tooltip-content={"Skills"}
          data-tooltip-id="skills"
        >
          <i className="fa-solid fa-list hover:scale-110 duration-300"></i>
          <ReactTooltip id="skills" className="scale-75" />
        </Link>
        <Link
          to="projects"
          spy={true}
          smooth={true}
          duration={1000}
          activeClass="active"
          onSetActive={() => handleSetActive("projects")}
          className={`pb-1 transition-colors duration-300 ease-in cursor-pointer ${
            activeElement === "projects"
              ? theme === "dark"
                ? "border-b-2 border-white"
                : "border-b-2 border-black"
              : "border-0"
          }`}
          data-tooltip-content={"Projects"}
          data-tooltip-id="projects"
        >
          <i className="fa-solid fa-suitcase hover:scale-110 duration-300"></i>
          <ReactTooltip id="projects" className="scale-75" />
        </Link>
        <Link
          to="contact"
          spy={true}
          smooth={true}
          duration={1000}
          activeClass="active"
          onSetActive={() => handleSetActive("contact")}
          className={`pb-1 transition-colors duration-300 ease-in cursor-pointer ${
            activeElement === "contact"
              ? theme === "dark"
                ? "border-b-2 border-white"
                : "border-b-2 border-black"
              : "border-0"
          }`}
          data-tooltip-content={"Contact"}
          data-tooltip-id="contact"
        >
          <i className="fa-solid fa-envelope hover:scale-110 duration-300"></i>
          <ReactTooltip id="contact" className="scale-75" />
        </Link>

        <button
          className="cursor-pointer"
          onClick={() => toggleTheme()}
          data-tooltip-content={"Dark / Light"}
          data-tooltip-id="dl"
        >
          {theme === "light" ? (
            <i className="fa-solid fa-moon hover:scale-110 duration-300"></i>
          ) : (
            <i className="fa-solid fa-sun hover:scale-110 duration-300"></i>
          )}
        </button>
        <ReactTooltip id="dl" className="scale-75" />
        <div className="flex gap-10 items-center justify-center px-4 absolute right-0">
          <a
            href="https://www.linkedin.com/in/debraj1234/"
            target="_blank"
            data-tooltip-content={"Linkedin"}
            data-tooltip-id="linkedin"
          >
            <i className="fa-brands fa-linkedin text-2xl hover:scale-110 duration-300"></i>
            <ReactTooltip id="linkedin" className="scale-75" />
          </a>
          <a
            href="https://github.com/Debraj550"
            target="_blank"
            data-tooltip-content={"Github"}
            data-tooltip-id="github"
          >
            <i className="fa-brands fa-github text-2xl  hover:scale-110 duration-300"></i>
            <ReactTooltip id="github" className="scale-75" />
          </a>
          <a href="https://leetcode.com/debrajdhar100/" target="_blank">
            <i
              className="fa-solid fa-code text-2xl hover:scale-110 duration-300"
              data-tooltip-content={"Leetcode"}
              data-tooltip-id="leetcode"
            ></i>
            <ReactTooltip id="leetcode" className="scale-75" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
