import React, { useRef, useState } from "react";
import ReactDOM from "react-dom/client";
import { motion } from "framer-motion";
import Header from "./components/Header";
import { Element } from "react-scroll";
import Home from "./components/Home";
import About from "./components/About";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Lottie from "lottie-react";
import scrolldownicon from "./assests/scrolldown.json";
import "./app.css";
import { BrowserRouter } from "react-router-dom";
import ThemeContext from "./utils/ThemeContext";
import classNames from "classnames";
import RevealTop from "./utils/RevealTop";
import RevealZoom from "./utils/RevealZoom";
import RevealRightSlide from "./utils/RevealRightSlide";
import RevealLeftSlide from "./utils/RevealLeftSlide";

const AppLayout = () => {
  const [theme, setTheme] = useState("dark");

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <BrowserRouter>
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <div
          className={classNames(
            "app",
            theme === "light"
              ? "bg-white text-black"
              : "bg-black/90 text-gray-300"
          )}
        >
          <Header />

          <Element name="home">
            <RevealTop>
              <Home />
            </RevealTop>
          </Element>

          <Element name="about">
            <RevealLeftSlide>
              <About />
            </RevealLeftSlide>
          </Element>

          <Element name="skills">
            <RevealRightSlide>
              <Skills />
            </RevealRightSlide>
          </Element>

          <Element name="projects">
            <Projects />
          </Element>

          <Element name="contact">
            <RevealTop>
              <Contact />
            </RevealTop>
          </Element>

          <div className="flex justify-end w-full p-4 fixed bottom-0">
            <Lottie
              className="w-14 font-bold cursor-pointer"
              animationData={scrolldownicon}
            />
          </div>
        </div>
      </ThemeContext.Provider>
    </BrowserRouter>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<AppLayout />);
