import fs1 from "/static/FakeSwiggy/image1.png";
import fs2 from "/static/FakeSwiggy/image2.png";
import q1 from "/static/Qna-Guruji/image1.png";
import q2 from "/static/Qna-Guruji/image2.png";
import t1 from "/static/Tic-Tac-Toe/image1.png";
import t2 from "/static/Tic-Tac-Toe/image2.png";
import bf1 from "/static/BookFantasia/image1.png";
import dd1 from "/static/debrajdhar.com/image1.png";
import dd2 from "/static/debrajdhar.com/image2.png";

const imageMap = [
  {
    imageid: 1,
    images: [fs1, fs2],
  },
  {
    imageid: 2,
    images: [q1, q2],
  },
  {
    imageid: 3,
    images: [dd1, dd2],
  },
  {
    imageid: 4,
    images: [bf1],
  },
];
export default imageMap;
