import React from "react";

const Skilllist = ({ data }) => {
  return (
    <div className=" p-10 flex justify-center flex-wrap w-8/12 overflow-hidden">
      {data.skills.map((skill, idx) => (
        <div key={idx} className="my-3 mx-3 scale-110 rounded-lg">
          {skill.logo ? (
            <div className="cursor-pointer transition-all duration-300 hover:translate-y-[-0.3rem] shadow-md">
              <img className="" src={skill.logo}></img>
            </div>
          ) : (
            <h1 className="border border-black p-1 rounded-lg shadow-md">
              {skill.name}
            </h1>
          )}
        </div>
      ))}
    </div>
  );
};

export default Skilllist;
