import React, { useContext } from "react";
import ThemeContext from "../utils/ThemeContext";

const ProjectCard = ({ data }) => {
  const { theme } = useContext(ThemeContext);

  return (
    <div className="w-[300px] h-[150px] flex flex-col justify-between overflow-hidden">
      <div className="heading flex flex-col gap-2 my-2 ">
        <span className="text-2xl font-bold ">{data.name}</span>
        <p className="text-gray-400 font-semibold font-raleway font-lg">
          {data.shortdescription}
        </p>
      </div>
      <div className="links flex gap-4 mr-2 h-max my-2">
        {data.demolink && (
          <a
            href={data.demolink}
            className={`link px-4 py-2 text-sm rounded-full cursor-pointer transition-all hover:scale-95 ${
              theme == "light" ? "bg-black text-white" : "bg-white text-black"
            }`}
            target="_blank"
          >
            <i className="fa-solid fa-play"></i>
          </a>
        )}
        <a
          href={data.github}
          className={`link px-4 py-2 text-sm rounded-full cursor-pointer transition-all hover:scale-95 ${
            theme === "light" ? "bg-black text-white" : "bg-white text-black"
          }`}
          target="_blank"
        >
          <i className="fa-brands fa-github"></i>
        </a>
      </div>
    </div>
  );
};

export default ProjectCard;
