import React, { useEffect, useState } from "react";
import imageMap from "../data/imageMap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Lottie from "lottie-react";
import { ThreeCircles } from "react-loader-spinner";

const ProjectDetails = ({ data }) => {
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchImages = () => {
    const res = imageMap.filter((x) => x.imageid === data.imageid);
    if (res[0]) setImages(res[0].images);
    else setImages([]);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    const timeoutId = setTimeout(() => {
      fetchImages();
    }, 2000);
    return () => clearTimeout(timeoutId);
  }, [data]);

  return (
    <div className="border border-blue-400 shadow-md min-h-full px-4 py-2 relative rounded-xl overflow-hidden">
      <div>
        <div className="links f my-4 flex gap-4 mr-2 right-0 top-0 absolute">
          {data.demolink && (
            <a
              href={data.demolink}
              className="link px-5 py-2 text-lg bg-black text-white rounded-lg cursor-pointer transition-all hover:scale-95"
              target="_blank"
            >
              Demo <i className="fa-solid fa-play"></i>
            </a>
          )}
          <a
            href={data.github}
            className="github px-5 py-2 text-lg bg-black text-white rounded-lg cursor-pointer transition-all hover:scale-95"
            target="_blank"
          >
            View <i className="fa-brands fa-github"></i>
          </a>
        </div>
      </div>

      <div className="heading flex flex-col gap-2 my-2 pb-4 mb-4">
        <span className="text-4xl font-bold text-blue-500">{data.name}</span>
        <p className="w-9/12">{data.description}</p>
      </div>

      <div className="scrollable-content max-h-[38rem] overflow-y-auto">
        {images && (
          <div className="all-images flex justify-center items-center">
            {loading ? (
              <div className="px-1 flex items-center h-80 overflow-y-clip">
                <ThreeCircles
                  height="100"
                  width="100"
                  color="#4fa94d"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="three-circles-rotating"
                  outerCircleColor=""
                  innerCircleColor=""
                  middleCircleColor=""
                />
              </div>
            ) : (
              images.map((image, index) => (
                <div
                  className={`image${
                    index + 1
                  } px-1 flex items-center h-80 overflow-y-clip`}
                  key={index}
                >
                  <div>
                    <LazyLoadImage
                      alt={image.alt}
                      height={image.height}
                      src={image}
                      width={image.width}
                    />
                  </div>
                </div>
              ))
            )}
          </div>
        )}
        <div className="features my-2 py-2 font-raleway">
          <h1 className="font-semibold text-xl font-raleway mb-2">
            Key Features -
          </h1>
          {data.features && (
            <div className="ml-4">
              {data.features.map((feature, index) => (
                <div key={index} className="flex gap-2 ">
                  <li>{`${feature}`}</li>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="techstack my-2 py-2 font-raleway">
          <h1 className="font-raleway font-semibold text-xl">Technologies -</h1>
          <div className="flex py-4 px-4 gap-4">
            {data.techstack.map((tech, idx) => (
              <div key={idx} className="border-l px-2 border-gray-600 w-3/12 ">
                <h1 className="text-lg font-mono font-semibold">{tech.name}</h1>
                <h2>{tech.stack}</h2>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
