import React, { useRef, useState } from "react";
import contact from "../assests/contact.png";
import { Tooltip as ReactTooltip } from "react-tooltip";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();
    let temp = {
      from_name:  name ,
      email_id:  email ,
      message:  msg ,
    };
    console.log(temp);
    if (!name || !email || !msg) {
      toast.error("One or more fields missing.");
      return;
    }

    emailjs
      .send("service_rxlvscq", "template_ccopbiq", temp, "PJyRNNBBAt2SxVLuW")
      .then(
        (result) => {
          console.log(result.text);
          toast.success(
            "Thank you for reaching out. I'll get back to you soon."
          );
          setEmail("");
          setName("");
          setMsg("");
        },
        (error) => {
          console.log(error.text);
          toast.error("Try again later. Something went wrong. (Server Error)");
        }
      );
  };

  console.log(name, email, msg);
  return (
    <div className="m-auto flex flex-col items-center justify-center gap-10 p-20">
      <h1 className="font-bold text-4xl mt-10 mb-10 p-5">Contact Me</h1>

      <div className="flex px-5 pb-5 pt-1">
        <div className="w-6/12 m-auto hidden lg:block">
          <img className="w-80 rounded-full" src={contact}></img>
        </div>
        <div className="flex flex-col m-auto gap-3">
          <h1 className="text-xl font-bold font-raleway text-gray-500">
            Fill up the following form to reach out to me -
          </h1>

          <div>
            <form
              ref={form}
              onSubmit={sendEmail}
              className="flex flex-col gap-2"
            >
              <label htmlFor="user_name">Name</label>
              <input
                type="text"
                name="from_name"
                id="from_name"
                value={name}
                className="border border-black rounded-lg px-2 py-1 text-black"
                onChange={(e) => setName(e.target.value)}
              />
              <label htmlFor="user_email">Email</label>
              <input
                type="email"
                name="email_id"
                id="email_id"
                value={email}
                className="border border-black rounded-lg px-2 py-1 text-black"
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                value={msg}
                className="w-full h-[150px] border border-black rounded-lg px-2 py-1 text-black"
                onChange={(e) => setMsg(e.target.value)}
              />
              <button
                type="submit"
                className="px-4 py-2 bg-red-500 font-bold text-white rounded-lg"
              >
                Send
              </button>
            </form>
          </div>
          <h1 className="text-xl font-bold font-raleway text-gray-500">
            Social Links -
          </h1>
          <div className="links flex flex-col gap-2 ">
            <div className="flex gap-6">
              <a
                href="https://www.linkedin.com/in/debraj1234/"
                target="_blank"
                data-tooltip-content={"Linkedin"}
                data-tooltip-id="linkedin"
              >
                <i className="fa-brands fa-linkedin text-2xl hover:scale-110 duration-300"></i>
                <ReactTooltip id="linkedin" className="scale-75" />
              </a>
              <a
                href="https://github.com/Debraj550"
                target="_blank"
                data-tooltip-content={"Github"}
                data-tooltip-id="github"
              >
                <i className="fa-brands fa-github text-2xl  hover:scale-110 duration-300"></i>
                <ReactTooltip id="github" className="scale-75" />
              </a>
              <a href="https://leetcode.com/debrajdhar100/" target="_blank">
                <i
                  className="fa-solid fa-code text-2xl hover:scale-110 duration-300"
                  data-tooltip-content={"Leetcode"}
                  data-tooltip-id="leetcode"
                ></i>
                <ReactTooltip id="leetcode" className="scale-75" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover={false}
        theme="colored"
      />
    </div>
  );
};

export default Contact;
