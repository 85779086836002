import React, { useContext, useEffect, useState } from "react";
import skilldata from "../data/skilldata";
import Skilllist from "./Skilllist";
import { motion, AnimatePresence } from "framer-motion";
import arrowdown from "../assests/arrowdown.json";
import Lottie from "lottie-react";
import ThemeContext from "../utils/ThemeContext";

const Skills = () => {
  const [activeButton, setActiveButton] = useState(0);
  const { theme } = useContext(ThemeContext);
  const handleButtonClick = (index) => {
    setActiveButton(index);
  };
  useEffect(() => {
    setActiveButton(0);
  }, [skilldata]);

  return (
    <div className=" w-10/12 m-auto py-40 overflow-hidden">
      <div className="py-4 text-center shadow-md w-fit px-6 rounded-lg m-auto">
        <h1 className="font-raleway text-xl">
          I'm well versed with{" "}
          <span className="font-semibold">Data Structures and Algorithms</span>{" "}
          <br></br> along with OOPS concepts and different Design principles /
          patterns.
          <br></br>
        </h1>
        <div
          className={`flex justify-center items-center gap-2 mt-10 font-raelway text-lg px-4 py-2  rounded-xl w-fit mx-auto border-red-500 border`}
        >
          <Lottie
            className="h-14"
            animationData={arrowdown}
            loop={true}
          ></Lottie>
          <h1>Check out my skills and technologies below </h1>
          <Lottie
            className="h-14"
            animationData={arrowdown}
            loop={true}
          ></Lottie>
        </div>
      </div>
      <div className="flex justify-center ">
        <div className="h-fit flex justify-center gap-10 mt-10 border-y-2 py-4 w-fit px-10 ">
          {skilldata.map((skill, index) => (
            <button
              key={index}
              className={`px-4 py-2 border ${
                activeButton === index
                  ? "bg-black text-white"
                  : "border-gray-400"
              } rounded-lg hover:border-black`}
              onClick={() => handleButtonClick(index)}
            >
              <p className="transition-all duration-300 hover:scale-105">
                {skill.name}
              </p>
            </button>
          ))}
        </div>
      </div>

      <motion.div
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={{
          hidden: { opacity: 0, scale: 0 },
          visible: { opacity: 1, scale: 1 },
        }}
        transition={{
          delay: 0.2,
          duration: 0.4,
        }}
        className="py-4 my-4 flex justify-center"
        key={activeButton}
      >
        <Skilllist key={activeButton} data={skilldata[activeButton]} />
      </motion.div>
    </div>
  );
};

export default Skills;
