import Lottie from "lottie-react";
import React, { useState } from "react";

import chipsCat from "../assests/chipsCat.json";
const About = () => {
  const [highlights, setHighlights] = useState(false);
  const handleHighlights = () => {
    setHighlights((prev) => !prev);
  };
  return (
    <div className="w-10/12 mx-auto flex items-center justify-center py-20 my-20 sm: flex-col lg:flex-row overflow-hidden">
      <div className="p-10 w-6/12 flex flex-col gap-1">
        <div className="w-9/12 m-auto">
          <h1 className="text-lg leading-loose font-medium text-center">
            Don't worry I am waiting while you are reading about me. Take your
            sweet time. <i class="fa-solid fa-face-smile"></i>{" "}
            <i class="fa-solid fa-face-smile"></i>{" "}
          </h1>
        </div>
        <div className="w-8/12 m-auto">
          <Lottie animationData={chipsCat}></Lottie>
        </div>
      </div>

      <div className="w-8/12 px-10 ">
        <div className="about details font-sans min-h-[28rem] flex items-center justify-center ">
          {!highlights ? (
            <h1>
              <ul className="!leading-loose flex gap-2 flex-col">
                <li>
                  <i class="fa-solid fa-arrow-right"></i> Experienced in Full
                  Stack Development, Machine Learning / Deep Learning, and Cloud
                  Technologies.
                </li>
                <li>
                  <i class="fa-solid fa-arrow-right"></i> Developed functional,
                  beautiful and scalable high quality solutions.
                </li>
                <li>
                  <i class="fa-solid fa-arrow-right"></i> 3+ years of experience
                  as a React UI developer and a Database Administrator.
                </li>
                <li>
                  <i class="fa-solid fa-arrow-right"></i> Cross platform team
                  working capabilities with experience in Agile methodologies.
                </li>
                <li>
                  <i class="fa-solid fa-arrow-right"></i> Master's degree in
                  Computer Science from BITS Pilani, India with experience in
                  research practices along with proficiency different design
                  patterns and principles.
                </li>
              </ul>
            </h1>
          ) : (
            <h1 className="text-sm sm:text-base text-justify xl:text-lg font-raleway font-normal ">
              I am an Experienced Software Developer with a strong focus on
              <span className="font-bold">
                {" "}
                Full Stack Development, Machine Learning / Deep Learning, and
                Cloud Technologies
              </span>
              . My professional journey is defined by an unyielding commitment
              to delivering{" "}
              <span className="font-bold">
                functional, beautiful and scalable high quality solutions.
              </span>{" "}
              My experience extends beyond software development; I have also
              excelled in the role of a{" "}
              <span className="font-bold">
                React developer and Database Administrator
              </span>
              , where I fostered seamless collaboration across diverse teams and
              established strong relationships with valued business clients.
              What sets me apart is my proactive approach to{" "}
              <span className="font-bold">
                staying current with the latest industry trends and emerging
                technologies.
              </span>{" "}
              <br />
              My academic background includes a{" "}
              <span className="font-bold">
                Master's degree in Computer Science from BITS Pilani, India
              </span>
              , which has honed my abilities in{" "}
              <span className="font-bold">
                research practices and intricate design patterns of software
                development.
              </span>
            </h1>
          )}
        </div>
        <div className="credentials flex text-lg justify-center gap-8">
          <a
            href="https://drive.google.com/file/d/1Zvpub6bPrr8RSBn-mQHvBEY0nIwzr7gZ/view?usp=sharing"
            target="_blank"
            className="py-2 px-6 my-4 border border-black font-bold rounded-full hover:scale-105 transition-all 
            hover:bg-black hover:text-white"
          >
            <span className="flex justify-center items-center gap-3">
              <p>Resume</p> <i className="fa-solid fa-download"></i>
            </span>
          </a>
          <button
            onClick={() => handleHighlights()}
            className="py-2 px-6 my-4 border border-black  font-bold rounded-full hover:scale-105 transition-all 
            hover:bg-black hover:text-white"
          >
            {!highlights ? "TL;DR" : "Hightlights"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default About;
