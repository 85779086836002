import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

const RevealZoom = ({ children }) => {
  const ref = useRef(null);
  const isInView = useInView(ref);
  const mainControl = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControl.start("visible");
    }
    if (!isInView) mainControl.start("hidden");
  }, [isInView]);

  return (
    <div ref={ref} className="relative overflow-hidden">
      <motion.div
        initial="hidden"
        animate={mainControl}
        variants={{
          hidden: { opacity: -100 },
          visible: { opacity: 1 },
        }}
        transition={{
          delay: 0.3,
          duration: 0.5,
        }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default RevealZoom;
